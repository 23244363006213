import {getUserData} from "@/auth/utils";

export default [
  {
    header: 'Generators',
    action: 'read',
    resource: 'Generators',
  },
  {
    title: 'CoD Bo3',
    icon: 'SettingsIcon',
    tag: 'soon',
    tagVariant: 'light-danger',
    children: [
      {
        title: 'Random Loadout',
        route: 'generators-bo3rng',
        tag: 'soon',
        tagVariant: 'light-danger',
        action: 'read',
        resource: 'Generators',
      }
    ],
    disabled: getUserData().role === 'client',
    action: 'read',
    resource: 'Generators',
  },
]
