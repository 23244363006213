import {getUserData} from "@/auth/utils";

export default [
  {
    header: 'Tools',
    action: 'read',
    resource: 'Tools',
  },
  {
    title: 'Image Tools',
    icon: 'ImageIcon',
    tag: 'new!',
    tagVariant: 'light-success',
    children: [
      {
        title: 'Curse',
        tag: 'new!',
        tagVariant: 'light-success',
        route: 'image-curse',
        action: 'read',
        resource: 'Tools',
      }
    ],
    action: 'read',
    resource: 'Tools',
  },
  {
    title: 'Video Tools',
    icon: 'FilmIcon',
    tag: 'soon',
    tagVariant: 'light-danger',
    children: [
      {
        action: 'read',
        resource: 'Tools',
      }
    ],
    disabled: getUserData().role === 'client',
    action: 'read',
    resource: 'Tools',
  },
]
